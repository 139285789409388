exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-about-js": () => import("./../../../src/templates/about.js" /* webpackChunkName: "component---src-templates-about-js" */),
  "component---src-templates-artwork-js": () => import("./../../../src/templates/artwork.js" /* webpackChunkName: "component---src-templates-artwork-js" */),
  "component---src-templates-artworks-js": () => import("./../../../src/templates/artworks.js" /* webpackChunkName: "component---src-templates-artworks-js" */),
  "component---src-templates-event-js": () => import("./../../../src/templates/event.js" /* webpackChunkName: "component---src-templates-event-js" */),
  "component---src-templates-events-js": () => import("./../../../src/templates/events.js" /* webpackChunkName: "component---src-templates-events-js" */),
  "component---src-templates-festival-js": () => import("./../../../src/templates/festival.js" /* webpackChunkName: "component---src-templates-festival-js" */),
  "component---src-templates-resources-js": () => import("./../../../src/templates/resources.js" /* webpackChunkName: "component---src-templates-resources-js" */)
}

